import moment from "moment";

export default {
  name: "TOperationalProcess",
  data() {
    return {
      identifier: {
        processStatus: "",
      },
      property: {
        animation: {
          addOperationalProcess: {
            isLoading: false,
          },
        },
        listElement: {
          operationalProcess: {
            rows: 0,
            perPage: 10,
            currentPage: 0,
            message: "",
            downloading: false,
          },
        },
        filterDto: {
          officeCode: "",
        },
      },
      dataForm: {
        officeName: "",
        date: "",
      },
    };
  },
  methods: {
    helperPrepareClosingOperational() {
      const getDataFromSession = JSON.parse(
        this.decryptBASE64(sessionStorage.getItem("@vue-session/auth-bundle"))
      );

      this.dataForm.officeName = getDataFromSession.officeName;
      moment.locale("ID");
    },

    async clickButtonOperationalProcess() {
      if (window.navigator.onLine === false) {
        this.$buefy.toast.open(this.$NotificationUtils.connectionsError);
        return;
      } else {
        this.$buefy.dialog.confirm({
          message: "Buka Operasional Cabang ?",
          type: "is-success",
          onConfirm: async () => {
            this.property.animation.addOperationalProcess.isLoading = true;
            try {
              const resp = await this.$store.dispatch({
                type: "POST_DATA",
                endPoint: "process",
                reqUrl: "operational-process",
              });
              setTimeout(() => {
                if (resp.data.code === "SUCCESS") {
                  this.$buefy.toast.open({
                    message: "Cabang Berhasil Di Buka !",
                    type: "is-success",
                    duration: 1000,
                    position: "is-top",
                    queue: false,
                  });
                } else if (resp.data.message === "TIME ALREADY CREATED") {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: `Cabang Sudah Di Buka Sebelumnya`,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                } else {
                  this.$buefy.dialog.alert({
                    title: "Gagal !",
                    message: resp.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          resp.data.errorFields
                        )
                      : resp.data.message,
                    confirmText: "Ok",
                    type: "is-danger",
                  });
                }
              }, 1500);
            } catch (error) {
              setTimeout(() => {
                console.log(error.response);
                this.$buefy.dialog.alert({
                  title: "Gagal !",
                  message: error.response
                    ? error.response.data.errorFields
                      ? this.globalNotificationErrorFieldToText(
                          error.response.data.errorFields
                        )
                      : error.response.statusText
                    : "Terjadi Kesalahan",
                  confirmText: "Ok",
                  type: "is-danger",
                });
              }, 1500);
            } finally {
              setTimeout(() => {
                this.property.animation.addOperationalProcess.isLoading = false;
              }, 1000);
            }
          },
        });
      }
    },
    async getOperationalProcessStatus() {
      if (window.navigator.onLine === false) {
        this.$buefy.dialog.alert(this.$NotificationUtils.connectionsError);
        return;
      } else {
        try {
          const resp = await this.$store.dispatch({
            type: "GET_DATA_FIND_BY",
            endPoint: "process",
            reqUrl: "operational-process/status",
          });
          if (resp.data.code === "SUCCESS") {
            this.identifier.processStatus = resp.data.data.processStatus;
          }
        } catch (error) {}
      }
    },
    async getDate() {
      this.dataForm.date = this.fetchAppSession(
        "@vue-session/application"
      ).system_date;
    },
  },
  mounted() {
    this.getDate();
    this.helperPrepareClosingOperational();
    this.getOperationalProcessStatus();
  },
};
